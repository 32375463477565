 
// Filename - components/Footer.js
 
import React from "react";
import styled from 'styled-components';
import {BrowserRouter as Router, Routes, Route, Link, Switch} from "react-router-dom";

function Socials() {
    return (
      <fa className = "icon-soc">
        <Link className="fa fa-instagram" to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvs-_Ip-WEAxXJlokEHSqjBlEQFnoECAYQAQ&url=https%3A%2F%2Fwww.instagram.com%2Fcmlcornell%2F%3Fhl%3Den&usg=AOvVaw2npZx9w-ZO8XVG4o-xDkJs&opi=89978449"></Link>
        <Link className="fa fa-twitter" to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvs-_Ip-WEAxXJlokEHSqjBlEQ6F56BAgOEAE&url=https%3A%2F%2Ftwitter.com%2Fcmlcornell%3Fref_src%3Dtwsrc%255Egoogle%257Ctwcamp%255Eserp%257Ctwgr%255Eauthor&usg=AOvVaw3ItGxKFqEMDAPSfynOCKig&opi=89978449"></Link>
      </fa>
    );
  }
  
  const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    margin-top: 3.75em;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    // background-color: #f0f0f0; /* Example background color, adjust as needed */
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Footer2 = () => {
  return (
    <FooterContainer>
      {/* <ContentContainer>
        {/* <div className="soc2">
          <Socials />
        </div> */}
      {/* {</ContentContainer> */}
      <h5>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;
        © 2024 The Coalition for Mutual Liberation All rights reserved.
      </h5>
    </FooterContainer>
  );
};

const Footer = () => {
  return (
    <FooterContainer>
      <ContentContainer>
        <div className="soc">
          <Socials />
        </div>
      </ContentContainer>
      <h5>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;
        © 2024 The Coalition for Mutual Liberation All rights reserved.
      </h5>
    </FooterContainer>
  );
};

export default Footer;



  // function Footer() {
  //   return (
  //       <div className = "footer" display = "flex">
         
  //         <div className = "soc" position="sticky">
  //         <Socials />
  //         </div>

  //         <h3>© 2024 Corp All rights reserved.</h3>
  
  //       </div>
        
       
  
  //   );
  // };

  // export default Footer




