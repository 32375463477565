import React from "react";
import Footer from "./Footer.js";
const Media = () => {
    return (
        <div>
            <div>
            <h1>MEDIA</h1>
        </div>

<div className = "footer" width="100%" left = {0}>
<Footer/>

</div>

        </div>
        
    );
};
 
export default Media;