
import React, { useState, useEffect } from "react";
import InstagramEmbed from 'react-instagram-embed';
import queryString from 'querystring';
import cml from "./cml-fist3.png";
import Clock from "./Clock.js";
import Footer from "./Footer.js";
import Socials from "./Footer.js"

import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";

const Home = () => {
    return (
        <div className="homepage">
            <div className = "centered-text h7">
            <h7>THE COALITION FOR MUTUAL LIBERATION</h7>
            </div>
            
            <div className="container sec-wrapper" position="fixed">
            
           
           
            {/* <img className = {"pics"} src={cml} alt="giving-logo" height={200} width={200}/> */}
            <h3><br></br></h3>
            <h1 className = "expl">
            <i>
            <b>
            CORNELL STUDENTS ESTABLISH 
            <br/>
            LIBERATED ZONE ON CAMPUS!
            </b>
            </i>
            {/* <br></br>
            TO SUPPORT LOCAL ORGANIZERS */}
            </h1>
            <h2 className = "date">ACCESS THEIR PRESS RELEASE VIA OUR MEDIA DIRECTORY</h2>
            {/* <img className = {"pics"} src={cml} alt="giving-logo" height={150} width={150}/> */}
            <h3><br></br></h3><h3><br></br></h3>
            <h2 className = "date">CORNELL UNIVERSITY MUST DIVEST FROM</h2>
            {/* <h2 className = "time2 white">TO A VOTE ON DIVESTMENT FROM</h2> */}
            <h2 className = "red time2">GENOCIDE AND APARTHEID</h2>
            <h2 className = "time">IN PALESTINE</h2>
            {/* <Clock /> */}
            
            <h3><br></br></h3><h3><br></br><br></br><br></br></h3>
            <h5>© 2024 The Coalition for Mutual Liberation All rights reserved.</h5>
            </div>
            {/* <div>
                <fa >
                    <Link className="fa fa-instagram" to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvs-_Ip-WEAxXJlokEHSqjBlEQFnoECAYQAQ&url=https%3A%2F%2Fwww.instagram.com%2Fcmlcornell%2F%3Fhl%3Den&usg=AOvVaw2npZx9w-ZO8XVG4o-xDkJs&opi=89978449"></Link>
                    <Link className="fa fa-twitter" to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvs-_Ip-WEAxXJlokEHSqjBlEQ6F56BAgOEAE&url=https%3A%2F%2Ftwitter.com%2Fcmlcornell%3Fref_src%3Dtwsrc%255Egoogle%257Ctwcamp%255Eserp%257Ctwgr%255Eauthor&usg=AOvVaw3ItGxKFqEMDAPSfynOCKig&opi=89978449"></Link>
                </fa>
            </div> */}
        </div>
    );
};
 
export default Home;