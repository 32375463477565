import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Footer from "./Footer.js"
import flag from "./flag.jpg";

// var imgwidth = document.getElementById("about-body").imgwidth;
// alert(imgwidth);

const Referendum = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.about-us__content section');
      const scrollY = window.scrollY;

      for (const section of sections) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (scrollY >= sectionTop && scrollY < sectionTop + sectionHeight) {
        // if (scrollY == sectionTop) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array to run effect only once

  const handleClick = (sectionId) => {
    setActiveSection(sectionId);
    const targetSection = document.getElementById(sectionId);
    const offsetTop = targetSection.offsetTop - 16;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    });
  };
  
  // const handleClick = (sectionId) => {
  //   setActiveSection(sectionId);
  //   const targetSection = document.getElementById(sectionId);
  //   targetSection.scrollIntoView({ behavior: 'smooth', block: 'start'});
  // };

  return (
    <div className = "about" >

    
    <ReferendumWrapper>
      <ContentWrapper>
        

      
      <Sidebar>
          <div className="side">

          
          <ul>
            <SidebarItem
              active={activeSection === 'what-is'}
              onClick={() => handleClick('what-is')}
            >
            REFERENDUM 
            <br/>
            ON APRIL 18-19
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'what-will'}
              onClick={() => handleClick('what-will')}
            >
            REFERENDUM QUESTIONS
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'how-can'}
              onClick={() => handleClick('how-can')}
            >
            MAKE YOUR VOICE HEARD
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'why-is'}
              onClick={() => handleClick('why-is')}
            >
            WHY VOTE
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'will-anyone'}
              onClick={() => handleClick('will-anyone')}
            >
            VOTER PRIVACY
            </SidebarItem>
{/* 
            <SidebarItem
              active={activeSection === 'why-should'}
              onClick={() => handleClick('why-should')}
            >
            WHY SHOULD I VOTE YES?
            </SidebarItem> */}

            {/* <SidebarItem
              active={activeSection === 'misconceptions'}
              onClick={() => handleClick('misconceptions')}
            >
            COMMON MISCONCEPTIONS
            </SidebarItem> */}
          </ul>
          </div>
        </Sidebar> 
       
        
      {/* </ContentWrapper>
    </referendumWrapper> */}
<div className = "about-body" >


        <Content>
        {/* <h3>&nbsp;</h3> */}
    
        {/* <ImageWrapper>
          <StyledImage src={flag}/>
        </ImageWrapper> */}

        <img className = "pics" src={flag} alt="flag" style={{ maxWidth: '100%', height: 'auto' }}/>

        <h6>CML rally, 2023 (Cornell University)</h6>
          <Section id="what-is">
            <SectionTitle>REFERENDUM ON APRIL 18-19</SectionTitle>
            <SectionContent>

            <h3><p>
            
            </p></h3>
            </SectionContent>
          </Section>
          <Section id="what-will">
            <SectionTitle>REFERENDUM QUESTIONS</SectionTitle>
            <SectionContent>

            <h3><p>
            <b>
            Question 1: 
            </b>
            &nbsp;Should Cornell University call for a permanent ceasefire in Gaza?
            <br/><br/>
            <b>
            Question 2:
            </b> 
            &nbsp;Cornell has investments in companies supporting the ongoing war in Gaza, 
            which has been deemed as a ‘plausible genocide’ by the International Court of Justice in South Africa v. Israel. 
            Should Cornell University follow their 2016 Guidelines for Divestment and divest from the following weapons 
            manufacturers: BAE Systems, Boeing, Elbit Systems, General Dynamics, L3Harris Technologies, Leonardo, Lockheed Martin, 
            Northrop Grumman, RTX, and ThyssenKrupp?
            </p></h3>
            </SectionContent>

          </Section>
          <Section id="how-can">
            <SectionTitle>MAKE YOUR VOICE HEARD</SectionTitle>
            <SectionContent>

            <h3><p>
              <b>
              Comment:
              </b> 
              &nbsp;The online comment period for the two referendum questions is Monday, April 8 through Monday, April 15 and is open 
              to all members of the Cornell community. Comments can be in the affirmative or negative and will be visible 
              to voters. Submit a comment by April 15 via this Qualtrics form.
              <br/><br/>
              <b>
              Vote:
              </b> 
              &nbsp;The 36-hour voting period is Thursday, April 18 through Friday, April 19. The options will be Yes, No, and Unsure 
              for each question. It will be emailed to all Cornell undergraduates through a Qualtrics survey. Fill out this 1-minute 
              form for a reminder to vote.
            </p></h3>
            </SectionContent>

          </Section>
          
          <Section id="why-is">
            <SectionTitle>WHY VOTE</SectionTitle>
            <SectionContent>

            <h3><p>
            As a large, elite institution with ties to many corporations, Cornell holds significant power 
            over communities across the globe. President Martha Pollack makes the very decisions that impact 
            not only the student body, but the wider community. If the vote passes, the results will be sent to 
            President Pollack. She has 30 days to choose to respond to the wishes of the student body. If the vote fails, 
            nothing will happen.
            <br/><br/>
            As a Cornell student, divestment relates to where your tuition money goes. Cornell is currently putting a significant 
            portion of your tuition money towards the systematic killing of Palestinians. These actions go against 
            the promises that Cornell’s Board of Trustees made in the 2016 divestment guidelines. As outlined in 
            Cornell's 2016 Standard to Guide Divestment Consideration, the Board of Trustees must consider divestment 
            from companies whose actions constitute "genocide, apartheid, or systemic cruelty to children." 
            If you believe your tuition shouldn’t be used for war—and if you believe that Cornell should not be allowed 
            to make false promises to the student body—then vote YES to DIVEST. 
            </p></h3>
            </SectionContent>

          </Section>
          <Section id="will-anyone">
            <SectionTitle>VOTER PRIVACY</SectionTitle>
            <SectionContent>
              <h3>
              <p>
              No one will be able to see how you vote; all votes and voter data will be 
              handled by the Office of Assemblies, the administrative body of Student Assembly. 
              </p>
              </h3>
            </SectionContent>
          </Section>
          {/* <Section id="partnering-organizations">
            <SectionTitle>MEMBER ORGANIZATIONS</SectionTitle>
            <SectionContent>
            <h4>
            <p>
                
            </p>
            </h4>
            </SectionContent>
          </Section> */}

        </Content>
        </div>
      </ContentWrapper>
     
      
    </ReferendumWrapper>
    <div className='events-page-foot'>
    <div className = "footer">
    <Footer/>
    </div>
    </div>
    </div>
  );
};



const ReferendumWrapper = styled.div`
    // margin-top: 0.01em;
    // margin-bottom: 1.5em;
    margin-left: 0;
    margin-right: 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
  
    // }
  // @media (max-width: 768px) {
    
  // }

    // margin-top: 0px;
    // // margin-left: 9em;
    // margin-left: 4%;
    // margin-right: 4%;
    // width: 80%;
    // display: flex;
    // // @media (max-width: 480px) {
    // // width: 10%;
    // // }
`;

const ContentWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 2px;
  // max-width: 100%;
  width: 80%;

  // @media (max-width: 100%) {
  //   flex-direction: column;
  //   align-items: center;
  //   // width: 50%;
  }
`;

const Sidebar = styled.div`
  position: sticky;
//   margin-top: 1em;
  margin-left: 2.5%;
  margin-right: 1%;
  top: 14.8vh;
  overflow: break;
  height: 80%; /* Make the sidebar take the full height of the container */
   /* Enable scrolling within the sidebar */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  width: 25%;
  box-sizing: border;
  // border-radius: 8px;
  border-right: calc(1px + 0.25vw) solid #EE2A35;
  padding-right: 1.5%;
  align-items: center;
  justify-content: center;

  // @media (max-width: 1000px) {
  //   width: 2%;
  // }
`;



const SidebarItem = styled.li`
  font-size: calc(15px + 0.4vw); /* Match the font size of section headers */
  text-align: right;
  color: #000; /* Match the color of section headers */
  font-weight: ${({ active }) => (active ? 'bold' : 'light')};
  cursor: pointer;
  margin-bottom: calc(10px + 0.8vw);
  transition: color 0.3s ease;
  
  &:hover {
    color: #EE2A35; /* Hover color for better feedback */
  }

  @media (max-width: 730px) {
    font-size: calc(10px + 0.4vw);
  }

  @media (max-width: 482px) {
    font-size: calc(7.5px + 0.4vw);
  }

  @media (max-width: 382px) {
    font-size: calc(5px + 0.4vw);
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 30%;
  // border: 1px solid #EE2A35;
  width: 150%;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const Section = styled.section`
  margin-top: 0;
`;

const SectionTitle = styled.h1`
  color: black;
  font-size: calc(12px + 0.8vw);
  line-height: 142%;
  font-family: Libre Franklin, sans-serif;
`;

const SectionContent = styled.div`
  color: black;
  margin-top: 0px;
  font-size: calc(7.5px + 0.8vw);
  line-height: calc(17px + 0.8vw);
  font-family: Libre Franklin, sans-serif;
  
  // @media (max-width: 480px) {
  //   font-size: 10px;
  //   line-height: 20px;
  // }
`;

export default Referendum;