import React, { useState } from 'react';
//import Navbar from "./components/NavBar";
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link, useLocation} from "react-router-dom";
import Home from './pages/home';
// import Calendar from './pages/calendar';
import AboutUs from './pages/about-us';
import Referendum from './pages/get-involved';
import Donate from './pages/donate';
import Media from './pages/media';
import ScrollToTop from "./components/ScrollToTop";
//import {Footer} from "./components/Footer.js";
/*<<<<<<< HEAD
import cml from "./pictures/cml-fist.png";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';

function Navbar () {

  return(<nav>
    <div className = "nav-logo">
    <img className = "log" src={cml} alt="cml-logo" float="left" height={80} width={80} />
    <h2 className='CML-nav'>CML</h2>
   
 
    </div>
    
  

   
   <Link className = {"link-styles"} to="/">Home</Link>
  <Link className = {"link-styles"} to="/calendar">Calendar</Link>
  <Link className = {"link-styles"} to="/about-us">about-us</Link>
  <Link className = {"link-styles"} to="/donate">Donate</Link>

   
    
    
    
    
    
  </nav>);
 
    
    
   
=======*/
import Events from './pages/events';
import cml from "./pictures/cml-fist3.png";

function Navbar() {
  const location = useLocation();
  return (
    <nav>
      <div className="nav-logo">
        <Link to="/">
          <img className="log" src={cml} alt="cml-logo" height={70} width={70} />
        </Link>
      </div>
      {/* <Link className="nav-name" to="/">CML</Link> */}
      {/* <Link className="link-styles" to="/">Home</Link> */}
      {/* eslint-disable-next-line no-restricted-globals */}
      <Link className={`link-styles ${location.pathname === 'https://drive.google.com/drive/folders/1mG2qkzKEiT4WRgkwfp6Bi5spfGhTikA5?usp=sharing' ? 'active' : ''}`} to="https://drive.google.com/drive/folders/1mG2qkzKEiT4WRgkwfp6Bi5spfGhTikA5?usp=sharing">MEDIA</Link>
      <Link className={`centered-head link-styles ${location.pathname === '/about-us' ? 'active' : ''}`} to="/about-us">ABOUT US</Link>
      {/* eslint-disable-next-line no-restricted-globals */}
      <Link className={`link-styles ${location.pathname === '/events' ? 'active' : ''}`} to="/events">EVENTS</Link>
      {/* <Link className={`centered-head link-styles ${location.pathname === '/get-involved' ? 'active' : ''}`} to="/get-involved">GET INVOLVED</Link> */}
       {/* eslint-disable-next-line no-restricted-globals */}
      <Link className={`link-styles ${location.pathname === 'https://venmo.com/code?user_id=4021450398762163725&created=1710162939.9098701&printed=1' ? 'active' : ''}`} to="https://venmo.com/code?user_id=4021450398762163725&created=1710162939.9098701&printed=1">DONATE</Link>
      {/* <Link className="link-styles" activeClassName="active" to="/about-us">ABOUT US</Link>
      <Link className="link-styles"  activeClassName="active" to="/events">EVENTS</Link>
      <Link className="link-styles"  activeClassName="active" to="/donate">DONATE</Link> */}
    </nav>
  );

}

function Socials() {
  return (
    <fa>
      <Link className="fa fa-instagram" to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvs-_Ip-WEAxXJlokEHSqjBlEQFnoECAYQAQ&url=https%3A%2F%2Fwww.instagram.com%2Fcmlcornell%2F%3Fhl%3Den&usg=AOvVaw2npZx9w-ZO8XVG4o-xDkJs&opi=89978449"></Link>
      <Link className="fa fa-twitter" to="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwjvs-_Ip-WEAxXJlokEHSqjBlEQ6F56BAgOEAE&url=https%3A%2F%2Ftwitter.com%2Fcmlcornell%3Fref_src%3Dtwsrc%255Egoogle%257Ctwcamp%255Eserp%257Ctwgr%255Eauthor&usg=AOvVaw3ItGxKFqEMDAPSfynOCKig&opi=89978449"></Link>
    </fa>
  );
}

// function Footer() {
//   return (
//       <div className = "footer">
//         <h2>Social Media</h2>
         
//        <Socials />
      

//       </div>
      
     

//   );
// };



function App() {
  
  
  
  return (
 
    <div className = "app">

<Router>

<Navbar />
<Routes>
<Route exact path="/" element={<Home />} />
<Route path="/events" element={<Events />} />
<Route path="/about-us" element={<AboutUs />} />
{/* <Route path="/get-involved" element={<Referendum />} /> */}
<Route path="/media" element={<Media />} />
<Route path="/donate" element={<Donate />} />
</Routes>



<ScrollToTop />



</Router>

    </div>



      

   



   
      
     
      
  );
}


export default App;
