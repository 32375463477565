import React from 'react';
import styled from 'styled-components';
import Footer from "./Footer.js";

const EventCard = () => {
  // Sample data
  const eventsData = [
    
    {
      dateText: 'APRIL 11',
      dayText: 'Thurs.',
      timeText: '🕠 4:30 - 6:00 PM',
      title: 'THE ORGANIZING TRADITION: CML ORGANIZING WORKSHOP',
      locationText: '📍 TBD',
      // iconUrl: 'cml-fist.jpeg',
    },
    {
      dateText: 'April 13',
      dayText: 'Sat.',
      timeText: '🕕 5:00 PM - 7:00 PM',
      title: '[JVP x SJP] ISRAELISM SCREENING #1',
      locationText: '📍 TBD',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'April 14',
      dayText: 'Sun.',
      timeText: '🕒 1:00 PM - 3:00 PM',
      title: 'BLACK INTERNATIONALISM & SOLIDARITY WITH THE CONGO: WORKSHOP WITH PROF. RICKFORD',
      locationText: '📍 Southside Community Center',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'April 14',
      dayText: 'Sun.',
      timeText: '🕔 1:00 PM - 3:00 PM',
      title: '[JVP x IC SJP] INTERCAMPUS RALLY',
      locationText: '📍 Ho Plaza',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 14',
      dayText: 'Sun.',
      timeText: '🕔 3:00 PM - 5:00 PM',
      title: '[JVP x SJP] ISRAELISM SCREENING #2',
      locationText: '📍 Greater Ithaca Activities Center',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 15',
      dayText: 'Mon.',
      timeText: '🕔 11:00 AM - 12:00 PM',
      title: 'GRAD STUDENTS RALLY',
      locationText: '📍 Greater Ithaca Activities Center',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 15',
      dayText: 'Mon.',
      timeText: '🕔 5:00 PM - 6:30 PM',
      title: 'ART BUILD FOR PALESTINE',
      locationText: '📍 Goldwin Smith 156',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 16',
      dayText: 'Tues.',
      timeText: '🕛 7:00 PM - 9:00 PM',
      title: '[BREAD+PUPPET] THE HOPE PRINCIPLE SHOW: CITIZENS’ SHAME + HOPE IN THE TIME OF GENOCIDE',
      locationText: '📍 Community School of Music and Arts',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 22',
      dayText: 'Mon.',
      timeText: '🕠 3:30 PM - 5:30 PM',
      title: '[SJP] WHAT THE WORLD’S SILENCE SAYS: AN EVENING WITH GAZAN POET YAHYA ASHOUR',
      locationText: '📍 Klarman Auditorium KG70',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 22',
      dayText: 'Mon.',
      timeText: '🕠 5:30 PM - 8:00 PM',
      title: '[JVP x SJP] FREEDOM SEDER RECEPTION',
      locationText: '📍 Willard Straight Memorial Terrace',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 22',
      dayText: 'Mon.',
      timeText: '🕠 6:00 PM - 9:00 PM',
      title: '[JVP] FREEDOM SEDER',
      locationText: '📍 Willard Straight Memorial Terrace',
      // iconUrl: 'cml-fist2.png',
    },
    {
      dateText: 'APRIL 26',
      dayText: 'Fri.',
      timeText: '🕠 4:00 PM - 6:00 PM',
      title: '[SJP x AGSA] PALESTINE & SETTLER COLONIALISM: TEACH-IN WITH DR. BIKRUM GILL',
      locationText: '📍 Goldwin Smith G64',
      // iconUrl: 'cml-fist2.png',
    },
    // Add more events to this array
  ];

  return (
    
    <div className="events-page">
    <div className = "calendar" >
    <EventWrapper>
      {eventsData.map((event, index) => (
        <Event key={index}>
          <DateColumn>
            <DateInfo>
              <DateText>{event.dateText}</DateText>
              <DayText>{event.dayText}</DayText>
            </DateInfo>
          </DateColumn>
          <Detail>
            <DetailColumn>
              <TimeText>{event.timeText}</TimeText>
              <EventInfo>
                {/* <IconWrapper>
                  <img src={event.iconUrl}/>
                </IconWrapper> */}
                <EventTitle>{event.title}</EventTitle>
              </EventInfo>
              <LocationInfo>
                <LocationText>{event.locationText}</LocationText>
              </LocationInfo>
              {/* <RegisterButton>Add to Calendar</RegisterButton> */}
            </DetailColumn>
          </Detail>
        </Event>
      ))}
    </EventWrapper>
    
    </div>
    
    <div className='events-page-foot'>
    <div className = "footer">
    <Footer/>
    </div>
    </div>
    
    </div>


    
    
    
    

    
    

    
  );
};


const EventWrapper = styled.section`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-left: 8%;
  width: 80%;
  display; flex;
  flex-direction: column;
  align-items: center;
  // @media (max-width: ) {
  //   max-width: 100%;
  // }
  // @media (max-width: 768px) {
  //   // margin-left: 0;
  //   width: 60%; 
  // }
`;

const Event = styled.div`
  display: flex;
  // justify-content: right;
  gap: 0.75%;
  margin-bottom: 16px; /* Add margin between events */
  @media (max-width: 480px) {
    gap: 2%;
  }
`;

const DateColumn = styled.div`
  display: flex;
  justify-content: center
  flex-direction: column;
  line-height: normal;
  // border-right: calc(2px + 0.8vw) solid #EE2A35;
  width: 10%;
  padding-left: 2%; /* Add some padding */
`;

const DateInfo = styled.div`
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  // padding-bottom: 80px;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 10px;
  }
`;

const DateText = styled.time`
  color: #000;
  font-weight: 700;
  font-size: calc(4px + 0.8vw);
  text-align: left;
  font-family: 'Libre Franklin', sans-serif;
  // @media (max-width: 480px) {
  //   font-size: 4px;
  // }
`;

const DayText = styled.span`
  color: #000;
  margin-top: 3%;
  font-weight: lighter;
  font-size: calc(4px + 0.8vw);
  text-align: left;
  font-family: 'Libre Franklin', sans-serif;
  // @media (max-width: 480px) {
  //   text-align: right;
  // }
`;

const DetailColumn = styled.div`
  line-height: normal;
  width: 60%;
  margin: 2%;
  // @media (max-width: 860px) {
  //   flex-wrap: wrap;
  //   padding: 0 16px;
  // }
`;

const Detail = styled.div`
  // border-radius: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;
  // border-left: 5px solid #EE2A35;
  border-left: calc(1px + 0.25vw) solid #EE2A35;
  gap: 20px;
  width: 100%;
  // @media (max-width: 991px) {
  //   flex-wrap: wrap;
  //   padding: 0 20px;
  // }
`;

const TimeText = styled.time`
  color: #000;
  font-size: calc(5px + 0.8vw);
  font-weight: lighter;
  font-family: 'Libre Franklin', sans-serif;
`;

const EventInfo = styled.div`
  display: flex;
  margin-top: 2.5%;
  gap: 2%;
  width: 100%;

`;

// const IconWrapper = styled.div`

// justify-content: center;
// align-items: center;
// border-radius: 6px;
// background-color: rgba(214, 115, 0, 0.1);
// display: flex;
// padding: 9px 7px;

//   img {
//     max-width: 100%; /* Ensure the image doesn't exceed its container */
//     height: auto; /* Maintain aspect ratio */
//     max-height: 54px; /* Set the maximum height of the image */
//   }
// `;

const EventTitle = styled.h3`
  flex-grow: 1;
  color: #000;
  font-weight: 700;
  font-size: calc(7.5px + 0.8vw);
  font-family: 'Libre Franklin', sans-serif;
  margin: auto 0;
  // width: 80%
  // white-space: nowrap; /* Prevent text from wrapping */
  // max-width: 80%; /* Set maximum width to 80% of the container */

  // @media (max-width: 768px) {
  //   white-space: normal; 
  // }

`;

const LocationInfo = styled.div`
  display: flex;
  margin-top: 2.5%;
  gap: 1.25%;
  font-size: calc(5px + 0.8vw);
  font-weight: lighter;
  color: #000;
  font-family: 'Libre Franklin', sans-serif;
`;

const LocationText = styled.span`
  flex-grow: 1;
  margin: auto 0;
`;

// const RegisterButton = styled.button`
//   justify-content: center;
//   align-items: center;
//   border-radius: 8px;
//   border: 1px solid rgba(141, 141, 141, 1);
//   background-color: #fff;
//   margin-top: 16px;
//   color: #000;
//   padding: 13px 60px;
//   font-weight: 600;
//   font-size: 16px;
//   font-family: 'Inter', sans-serif;
//   cursor: pointer;
//   @media (max-width: 991px) {
//     padding: 13px 20px;
//   }
// `;

export default EventCard;