import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Footer from "./Footer.js"
import flag from "./flag.jpg";

// var imgwidth = document.getElementById("about-body").imgwidth;
// alert(imgwidth);

const AboutUs = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.about-us__content section');
      const scrollY = window.scrollY;

      for (const section of sections) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (scrollY >= sectionTop && scrollY < sectionTop + sectionHeight) {
        // if (scrollY == sectionTop) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array to run effect only once

  const handleClick = (sectionId) => {
    setActiveSection(sectionId);
    const targetSection = document.getElementById(sectionId);
    const offsetTop = targetSection.offsetTop - 16;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    });
  };
  
  // const handleClick = (sectionId) => {
  //   setActiveSection(sectionId);
  //   const targetSection = document.getElementById(sectionId);
  //   targetSection.scrollIntoView({ behavior: 'smooth', block: 'start'});
  // };

  return (
    <div className = "about" >

    
    <AboutUsWrapper>
      <ContentWrapper>
        

      
      <Sidebar>
          <div className="side">

          
          <ul>
            <SidebarItem
              active={activeSection === 'who-we-are'}
              onClick={() => handleClick('who-we-are')}
            >
            WHO WE ARE
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'member-organizations'}
              onClick={() => handleClick('member-organizations')}
            >
            COALITION MEMBERS
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'our-mission'}
              onClick={() => handleClick('our-mission')}
            >
            OUR MISSION
            </SidebarItem>
            <SidebarItem
              active={activeSection === 'our-focus'}
              onClick={() => handleClick('our-focus')}
            >
            OUR FOCUS
            </SidebarItem>
           
            <SidebarItem
              active={activeSection === 'divestment-demands'}
              onClick={() => handleClick('divestment-demands')}
            >
            DIVESTMENT DEMANDS
            </SidebarItem>

            <SidebarItem
              active={activeSection === 'general-demands'}
              onClick={() => handleClick('general-demands')}
            >
            DEMANDS FROM LIBERATED ZONE
            </SidebarItem>

            <SidebarItem
              active={activeSection === 'points-of-unity'}
              onClick={() => handleClick('points-of-unity')}
            >
            POINTS OF UNITY
            </SidebarItem>

            <SidebarItem
              active={activeSection === 'contact-us'}
              onClick={() => handleClick('contact-us')}
            >
            CONTACT US
            </SidebarItem>
          </ul>
          </div>
        </Sidebar> 
       
        
      {/* </ContentWrapper>
    </AboutUsWrapper> */}
<div className = "about-body" >


        <Content>
        {/* <h3>&nbsp;</h3> */}
    
        {/* <ImageWrapper>
          <StyledImage src={flag}/>
        </ImageWrapper> */}

        <img className = "pics" src={flag} alt="flag" style={{ maxWidth: '100%', height: 'auto' }}/>

        <h6>CML rally, 2023 (Cornell University)</h6>
          <Section id="who-we-are">
            <SectionTitle>WHO WE ARE</SectionTitle>
            <SectionContent>

            <h3><p>
                The Coalition for Mutual Liberation (CML) is a broad-based coalition 
                of over 40 organizations on Cornell University's Ithaca Campus and in the 
                surrounding community. Many of these orgnizations are publicly members of CML;
                the others wish to remain anonymous. 
                {/* We unite against all forms of oppression 
                which lead to dispossession and exploitation, including but not 
                limited to racism, patriarchy, colonialism, imperialism, 
                transphobia, homophobia, anti-Blackness, xenophobia, ableism, 
                Islamophobia, and anti-Semitism. We join international humanitarian
                organizations, political leaders, scholars, activists, and most recently the state of South Africa in
                condemning Israel's genocide of the Palestinian people. */}
            </p></h3>
            </SectionContent>
          </Section>
          <Section id="member-organizations">
            <SectionTitle>COALITION MEMBERS</SectionTitle>
            <SectionContent>

            <h4><p>
                The Arab Graduate Student Association
                <br></br>
                Asian Pacific Americans for Action
                <br></br>
                The Basic Needs Coalition
                <br></br>
                Black Students United
                <br></br>
                The Buddhist Sangha
                <br></br>
                The Cadre Journal
                <br></br>
                Climate Justice Cornell
                <br></br>
                Cornell Progressives
                <br></br>
                Ithaca Ceasefire Now
                <br></br>
                {/* The Ithaca Communist Party USA
                <br></br> */}
                Jewish Voice for Peace at Cornell
                <br></br>
                The Mass Education Campaign
                <br></br>
                The Muslim Educational and Cultural Association
                <br></br>
                El Movimiento Estudiantil Chicanx at de Aztlán
                <br></br>
                Native American and Indigenous Students at Cornell
                <br></br>
                {/* The Party for Socialism and Liberation Finger Lakes
                <br></br> */}
                The People’s Organizing Collective Cornell, United Students Against Sweatshops Local 3
                <br></br>
                The South Asian Council
                <br></br>
                Students for Justice in Palestine 
                <br></br>
                Young Democratic Socialists of America
            </p></h4>
            </SectionContent>

          </Section>
          <Section id="our-mission">
            <SectionTitle>OUR MISSION</SectionTitle>
            <SectionContent>

            <h3><p>
            Our mission is to educate, empower, and organize our community to take action against imperialism, settler colonialism, 
            and all other forms of oppression. Our struggles are deeply interconnected, and it is only through our 
            collective resistance that we will achieve mutual liberation.
            {/* Our mission is to unite and empower our community to combat all forms of oppression that 
            lead to dispossession and exploitation, including, but not limited to, imperialism, racism, white 
            supremacy, transphobia, homophobia, as well as religious- and gender-based violence. We come together 
            in solidarity with the people of Palestine in particular because Palestine is among the clearest 
            manifestations of American economic and military hegemony—the force that perpetuates the above forms 
            of domination across the world's historically exploited nations and populations. Our struggles are 
            deeply interconnected, and it is only as a unified front against imperialism in both its material and ideological 
            forms that we will achieve mutual liberation. None of us are free until Palestine is free. */}
            {/* Our mission is to unite and empower our community to combat all froms of oppression that 
            lead to diposession and exploitation, including, but not limited to, imperialism, racism, 
            white supremacy, transphobia, homophobia, as well as religious- and gender-based violence. 
            We come together in solidarity with the people of Palestine in particular because Palestine is 
            the region in which our university contributes most directly to American economic and military 
            hegemony—the force that manufactures and perpetuates the above forms of domination across the world's 
            historically exploited populations. Our struggles are deeply interconnected, 
            and it is only as a unified front against imperialism and its material and ideological 
            exponents that we will achieve mutual liberation. None of us are free until Palestine is free. */}
            </p></h3>
            </SectionContent>

          </Section>
          <Section id="our-focus">
            <SectionTitle>OUR FOCUS</SectionTitle>
            <SectionContent>

            <h3><p>
            Today, we join international humanitarian organizations, political leaders, scholars, activists, 
            and most recently the state of South Africa incondemning Israel's genocide of the Palestinian people. 
            We come together in solidarity with the people of Palestine in particular because Palestine is among 
            the clearest manifestations of American economic and military hegemony—the force that perpetuates 
            imperialism, racism, white supremacy, transphobia, homophobia, as well as religious- and gender-based 
            violence across the world's historically exploited nations and populations.
            </p></h3>
            </SectionContent>

          </Section>
          
          <Section id="divestment-demands">
            <SectionTitle>DIVESTMENT DEMANDS</SectionTitle>
            <SectionContent>
              <h3>
              <p>
                We find Cornell University complicit in the genocide of the Palestinian people through its endowment investments 
                in weapons manufacturers and military technology developers, its corporate and institutional partnerships with the 
                producers of these technologies, and its lack of screening procedures and transparency around these ties.
                Cornell must take immediate action to sever its ties with the US-backed Israeli siege on Palestine
                which has already left more than 30,000 Palestinians dead. We demand:
                <br></br><br></br>
                <b>
                1. Divestment from any company complicit in genocide, apartheid, or systematic cruelty
                against children perpetrated against Palestinians in Gaza and the West Bank, in
                accordance with Cornell's 2016 Standard to Guide Divestment Consideration. 
                </b>
                    &nbsp;As outlined in Cornell's 2016&nbsp;
                    <a href='https://cpb-us-e1.wpmucdn.com/blogs.cornell.edu/dist/3/6798/files/2016/06/FF2216-1kvnvuj.pdf'>
                    Standard to Guide Divestment Consideration</a>, the Board of
                    Trustees must consider divestment from companies whose actions constitute "genocide,
                    apartheid, or systemic cruelty to children." By doing business with Israel as it conducts its
                    genocide, responsibility for these three morally reprehensible actions fall on the shoulders of the
                    following weapons companies: BAE Systems, Boeing, Elbit Systems, General Dynamics,
                    L3Harris Technologies, Leonardo, Lockheed Martin, Northrop Grumman, RTX, and
                    ThyssenKrupp. In order for Cornell to abide by its own divestment standards and precedents for
                    divestment (in the cases of the Sudanese genocide and the fossil fuels industry), the university
                    must immediately liquidate all of its holdings in the companies listed above and enact a
                    moratorium on all investments in arms manufacturers that supply weapons, munitions, and other
                    military supplies to Israel.
                <br></br><br></br>
                <b>
                2. The termination of all corporate partnerships with companies complicit in the genocide,
                apartheid, or systematic cruelty towards children perpetrated against Palestinians in Gaza
                and the West Bank. 
                </b>
                    &nbsp;Cornell currently maintains corporate partnerships with a number of weapons companies whose
                    products have been used against civilians in Gaza. These companies include BAE Systems,
                    Boeing, and Lockheed Martin. Cornell Systems Engineering also partners with RTX (Raytheon),
                    which is described as being “an extended part of the Cornell Systems Engineering community.”
                    Cornell’s partnerships with these weapons companies amounts to complicity in the genocide of
                    the Palestinian people. We are therefore calling on Cornell University to sever their corporate
                    partnerships with these companies as soon as possible. We call on Cornell University to begin
                    this process immediately and to have fully dissolved these partnerships by the end of the 2024
                    calendar year.
                <br></br><br></br>
                <b>
                3. A comprehensive ban on the research and development of any technologies used by the
                Israeli Offensive Forces at the Jacobs Cornell-Technion Institute in New York City. 
                </b>
                    &nbsp;The Jacobs Technion-Cornell Institute, a partnership between Cornell University and the Israel
                    Institute of Technology (Technion), is part of Cornell Tech, a campus for graduate research in
                    New York City. Independently of Cornell Tech, Technion researches and develops geospatial,
                    intelligence, and weapons technologies used by the Israeli Ministry of Defense. Cornell Tech’s
                    publicly stated founding purpose is “to advance technology as a means to a better quality of life
                    for all communities [...] around the world.” Its “Diversity and Inclusion” mission includes
                    “[engaging] in research that promotes justice, equity, diversity, and inclusion” and “[educating
                    and training] ethical technology leaders of the future.” In light of Technion’s numerous
                    connections to Israel’s occupation and genocide in Palestine, Cornell Tech’s supposed
                    commitment to ethical and just technological development rings hollow. We demand a
                    comprehensive ban on the research and development of any technologies used by the Israel
                    Offensive Forces at the Cornell Tech/Technion Campus in New York City.
                <br></br><br></br>
                As Israel continues its relentless genocide in Gaza and further militarizes its occupation of the West
                Bank, the world watches as Palestinians are displaced, starved, and killed every day. The horrors of
                Israel’s siege on Gaza are broadcast in full display across multiple news outlets and social media
                platforms, and yet, the American institutions that fuel this violence refuse to act.
                <br></br><br></br>
                Thirty years ago, when over fifty other universities across the country divested from South African
                apartheid, Cornell faltered in its commitment to humanity and never severed its ties with a state
                dependent on the perpetuation of horrific racial violence. Today, the global community once again
                stands at a crossroad—Cornell University has the opportunity to do what it couldn’t three decades ago.
                <br></br><br></br>
                Cornell University must make a choice: to toe the line drawn by a foreign nation and remain complicit
                in the genocide of the Palestinian people, or to establish itself as a leader among elite educational
                institutions by being the first to materially recognize the Palestinian right to life and dignity.
                <br></br><br></br>
                We envision a future for Cornell University that does not fund and partner with the corporate entities
                responsible for the decimation of an entire people, their cultural artifacts, and the land they inhabit. The
                Board of Trustees must have the courage and moral fortitude to cut ties with Israel’s unrelenting
                campaign of violence against Palestine so that Cornell may truly do the greatest good. 
                <br></br><br></br>
                For more information about our divestment demands, the companies listed as divestment targets,
                Cornell's complicity in Israel's genocide against the Palestinian people, and Cornell's violation of its own
                standards, procedures, and values, see CML's full <a href='https://drive.google.com/file/d/1ozYoGZZEuIbcQZBk0WhqMxsq4DonLSE9/view?usp=drivesdk'>Divestment Report</a>.
              </p>
              </h3>
            </SectionContent>
          </Section>
          <Section id="general-demands">
            <SectionTitle>DEMANDS FROM LIBERATED ZONE</SectionTitle>
            <SectionContent>

            <h3><p>
            Cornell students, staff, faculty, and community members join the cross-campus wave of organizers establishing liberated 
            zones in solidarity with Gaza. The campers' ongoing act of nonviolent resistance will include teach-ins, art builds, and 
            other activities to highlight the urgency with which Cornell must act in response to the Israeli government's genocide of 
            Palestinians in Gaza. Students from across the globe have joined together to protest the genocide in Gaza during which the 
            Israeli Offensive Forces have murdered over 34,000 Indigenous Palestinians in under seven months. Students are organizing 
            in outrage that Palestinian universities have been obliterated with weapons funded and developed through Cornell University's 
            partnerships and investments. Distinctly, the Cornell University Board of Trustees adopted a commitment in 2016 to divest 
            from companies engaged in "genocide, apartheid, and systematic cruelty against children.” Cornell's failure to divest is 
            not only a violation of the university's stated policies, but also an act of genocide denialism.
            <br></br><br></br>
            Cornell’s refusal to cut ties to Palestinian genocide reflects its history of profiteering from the violent dispossession of Indigenous 
            Peoples across North America. Cornell is the largest beneficiary of the Morrill Act of 1862, which redistributed Indigenous land as 
            public domain to states to establish and endow land-grant institutions. Through the dispossession, Cornell accrued nearly 1 million acres 
            of land, some of which it sold for profit, and some to which it currently retains the rights. Today, Cornell showcases its land-grant status—its 
            status as an institution supposedly dedicated to the promotion of practical disciplines such as agriculture, mining, and engineering—to signal its 
            commitment to accessible higher education and mask its refusal to provide reparations or restitution to the 251 tribal nations affected by land-grant 
            dispossession. Cornell's settler colonial project in the United States is the foundation for its settler colonial interests in Palestine. Through this 
            encampment, students highlight Cornell's role in dispossession and genocide across the globe.
            <br></br><br></br>
            The encampment on the oldest commons on Cornell's campus invites all members of the community to support the students' demands that Cornell University:
            <br></br><br></br>
            1. Acknowledge its role in the national genocide of Indigenous Peoples through the Morrill Act and its sale of 977,909 acres of Indigenous land; return all 
            mineral interests to Tribal Nations dispossessed by the Morrill Act; provide restitution for the dispossessed nations; provide restitution for the Cayuga Nation; 
            establish an Indigenous Studies department; and return surplus land in New York state to the Haudenosaunee Confederacy, the Lenni Lenape, and their descendants 
            who have been forced out of New York.
            <br></br><br></br>
            2. Annually disclose a comprehensive account of its endowment and land holdings, and divest from entities involved in “morally reprehensible activities,” in 
            accordance with Cornell’s 2016 Standard to Guide Divestment Consideration.
            <br></br><br></br>
            3. End profit-generating partnerships, volunteer arrangements, and other significant corporate and academic affiliations with institutions involved in “morally reprehensible 
            activities,” including but not limited to the dissolution of the Jacobs-Technion Cornell Institute and all other partnerships with the Technion Israel Institute of Technology.
            <br></br><br></br>
            4. Call for an unconditional, permanent ceasefire in Gaza.
            <br></br><br></br>
            5. Establish a Palestinian Studies program housed in the College of Arts and Sciences, along with an accredited minor that is available to all undergraduate and graduate students. 
            Representatives from Cornell’s chapter of “Students for Justice in Palestine” and “Cornell Collective for Justice in Palestine” must serve on the committees that oversee the 
            hiring of the program’s faculty.
            <br></br><br></br>
            6. Publicly acknowledge and protect anti-Zionist speech, viewpoints, and histories in both religious and academic contexts. Recognize the legitimate and historical claim that 
            anti-Zionism is not anti-Semitism.
            <br></br><br></br>
            7. Remove all police from campus, beginning with the elimination of police presence at demonstrations. Replace police with an emergency response team composed of healthcare workers 
            and first responders trained in de-escalation. A majority of team members must be providers who share lived experiences and identities with Cornell’s diverse student body.
            <br></br><br></br>
            8. Ensure total legal and academic amnesty for all individuals involved with the Liberated Zone and related demonstrations. 
            </p></h3>
            </SectionContent>
          </Section>
          <Section id="points-of-unity">
            <SectionTitle>POINTS OF UNITY</SectionTitle>
            <SectionContent>
            <h3>
            <p>
            {/* <b> */}
                1. The principal contradiction of our world is that between the exploited nations and the exploiters in the imperial core: imperialism. 
                <br></br><br></br>
                2. The underdevelopment of the exploited nations was and is the dialectical necessity for the development of the exploiters.
                <br></br><br></br>
                3. Capitalism has always been a global, racialized system—primitive accumulation could not have occurred without genocide, enslavement, and ecocide.
                <br></br><br></br>
                4.Imperialism creates a stratification that rewards some proletarians as settlers and/or citizens, thus forming a labor aristocracy.
                <br></br><br></br>
                5. The labor aristocracy’s wages and incorporation into the nation-state allow them to benefit from the exploitation of the low-waged labor of the exploited nations, intensifying imperialism in the form of unequal exchange.
                <br></br><br></br>
                6. Unequal exchange precludes the universality and internationalism of the proletariat, and hinders the solidarity of the “workers of the world”.
                <br></br><br></br>
                7. Imperialism manifests itself in a variety of other ways today, in sanctions regimes, indebtedness, military intervention, nuclear aggression, extractivism, and other forms.
                <br></br><br></br>
                8. Capitalism cannot be defeated globally while imperialism persists—without anti-imperialism, efforts at socialism in the exploiting nations can only produce social imperialism.
                <br></br><br></br>
                9. The obligation of revolutionaries today is to challenge imperialism by any means necessary. In the exploiting nations, that primarily means acting in solidarity with anti-imperialist movements in the exploited nations. 
                <br></br><br></br>
                10. Solidarity cannot be simply symbolic—it must be material; it must be something we can hold in our hands.  
            {/* </b> */}
            </p>
            </h3>
            </SectionContent>
            
          </Section>
       
          <Section id="contact-us">
            <SectionTitle>CONTACT US</SectionTitle>
            <SectionContent>

            <h3><p>
            Information address: <a href='mailto:cml.information@proton.me'>cml.information@proton.me</a> 
            <br></br>
            Press address: <a href='mailto:cml.press@proton.me'>cml.press@proton.me</a>
            </p></h3>
            </SectionContent>

          </Section>
          
          {/* <Section id="partnering-organizations">
            <SectionTitle>MEMBER ORGANIZATIONS</SectionTitle>
            <SectionContent>
            <h4>
            <p>
                
            </p>
            </h4>
            </SectionContent>
          </Section> */}

        </Content>
        </div>
      </ContentWrapper>
     
      
    </AboutUsWrapper>
    <div className='events-page-foot'>
    <div className = "footer">
    <Footer/>
    </div>
    </div>
    </div>
  );
};



const AboutUsWrapper = styled.div`
    // margin-top: 0.01em;
    // margin-bottom: 1.5em;
    margin-left: 0;
    margin-right: 0;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
  
    // }
  // @media (max-width: 768px) {
    
  // }

    // margin-top: 0px;
    // // margin-left: 9em;
    // margin-left: 4%;
    // margin-right: 4%;
    // width: 80%;
    // display: flex;
    // // @media (max-width: 480px) {
    // // width: 10%;
    // // }
`;

const ContentWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 2px;
  // max-width: 100%;
  width: 80%;

  // @media (max-width: 100%) {
  //   flex-direction: column;
  //   align-items: center;
  //   // width: 50%;
  }
`;

const Sidebar = styled.div`
  position: sticky;
//   margin-top: 1em;
  margin-left: 2.5%;
  margin-right: 1%;
  top: 14.8vh;
  overflow: break;
  height: 80%; /* Make the sidebar take the full height of the container */
   /* Enable scrolling within the sidebar */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  width: 25%;
  box-sizing: border;
  // border-radius: 8px;
  border-right: calc(1px + 0.25vw) solid #EE2A35;
  padding-right: 1.5%;
  align-items: center;
  justify-content: center;

  // @media (max-width: 1000px) {
  //   width: 2%;
  // }
`;



const SidebarItem = styled.li`
  font-size: calc(15px + 0.4vw); /* Match the font size of section headers */
  text-align: right;
  color: #000; /* Match the color of section headers */
  font-weight: ${({ active }) => (active ? 'bold' : 'light')};
  cursor: pointer;
  margin-bottom: calc(10px + 0.8vw);
  transition: color 0.3s ease;
  
  &:hover {
    color: #EE2A35; /* Hover color for better feedback */
  }

  @media (max-width: 730px) {
    font-size: calc(10px + 0.4vw);
  }

  @media (max-width: 482px) {
    font-size: calc(7.5px + 0.4vw);
  }

  @media (max-width: 382px) {
    font-size: calc(5px + 0.4vw);
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 30%;
  // border: 1px solid #EE2A35;
  width: 150%;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const Section = styled.section`
  margin-top: 0;
`;

const SectionTitle = styled.h1`
  color: black;
  font-size: calc(12px + 0.8vw);
  line-height: 142%;
  font-family: Libre Franklin, sans-serif;
`;

const SectionContent = styled.div`
  color: black;
  margin-top: 0px;
  font-size: calc(7.5px + 0.8vw);
  line-height: calc(17px + 0.8vw);
  font-family: Libre Franklin, sans-serif;
  
  // @media (max-width: 480px) {
  //   font-size: 10px;
  //   line-height: 20px;
  // }
`;

// const StyledImage = styled.div`
//   width: 10%
// `;

// const ImageWrapper = styled.div`
//   width: 10%
// `;

export default AboutUs;


// import React from "react";
// // import border from "./border.jpg";

// const Demands = () => {
//     return (
//         <div className="container">
//             <h1 >MISSION STATEMENT</h1>
//             {/* <img className = {"pics"} src={border} alt="border" width={800}/> */}
//             <h1 >MEMBER ORGANIZATIONS</h1>
//             <h4>
//                 The Arab Graduate Student Association
//                 <br></br>
//                 Asian American Pacific Islanders for Action
//                 <br></br>
//                 The Basic Needs Coalition
//                 <br></br>
//                 Black Students United
//                 <br></br>
//                 The Buddhist Sangha
//                 <br></br>
//                 The Cadre Journal
//                 <br></br>
//                 Climate Justice Cornell
//                 <br></br>
//                 Cornell Progressives
//                 <br></br>
//                 Ithaca Ceasefire Now
//                 <br></br>
//                 The Ithaca Clown Posse
//                 <br></br>
//                 Jewish Voice for Peace at Cornell
//                 <br></br>
//                 The Mass Education Campaign
//                 <br></br>
//                 The Muslim Educational and Cultural Association
//                 <br></br>
//                 El Movimiento Estudiantil Chicanx at de Aztlán
//                 <br></br>
//                 Native American and Indigenous Students at Cornell
//                 <br></br>
//                 The Party for Socialism and Liberation Finger Lakes
//                 <br></br>
//                 The People’s Organizing Collective Cornell, United Students Against Sweatshops Local 3
//                 <br></br>
//                 The South Asian Collective
//                 <br></br>
//                 Students for Justice in Palestine 
//                 <br></br>
//                 Young Democratic Socialists of America
//             </h4>
//             <h1 >DIVESTMENT DEMANDS</h1>
//             <p><h3>
//                 The Coalition for Mutual Liberation is a broad-based coalition of over 40
//                 organizations on Cornell's Ithaca Campus and in the surrounding community. We
//                 unite against all forms of oppression which lead to dispossession and exploitation, including but not
//                 limited to racism, patriarchy, colonialism, imperialism, transphobia, homophobia, anti-Blackness,
//                 xenophobia, ableism, Islamophobia, and anti-Semitism. Today, we join international humanitarian
//                 organizations, political leaders, scholars, activists, and most recently the state of South Africa in
//                 condemning Israel's genocide of the Palestinian people. Furthermore, we find Cornell University
//                 complicit in this genocide through its endowment investments in weapons manufacturers and military
//                 technology developers, its corporate and institutional partnerships with the producers of these
//                 technologies, and its lack of screening procedures and transparency around these ties.
//                 Cornell must take immediate action to sever its ties with the U.S.-backed Israeli siege on Palestine
//                 which has already left more than 25,000 Palestinians dead. We demand:
//                 <br></br><br></br>
//                 <b>
//                 1. Divestment from any company complicit in genocide, apartheid, or systematic cruelty
//                 against children perpetrated against Palestinians in Gaza and the West Bank, in
//                 accordance with Cornell's 2016 Standard to Guide Divestment Consideration. 
//                 </b>
//                     &nbsp;As outlined in Cornell's 2016 Standard to Guide Divestment Consideration, the Board of
//                     Trustees must consider divestment from companies whose actions constitute "genocide,
//                     apartheid, or systemic cruelty to children." By doing business with Israel as it conducts its
//                     genocide, responsibility for these three morally reprehensible actions fall on the shoulders of the
//                     following weapons companies: BAE Systems, Boeing, Elbit Systems, General Dynamics,
//                     L3Harris Technologies, Leonardo, Lockheed Martin, Northrop Grumman, RTX, and
//                     ThyssenKrupp. In order for Cornell to abide by its own divestment standards and precedents for
//                     divestment (in the cases of the Sudanese genocide and the fossil fuels industry), the university
//                     must immediately liquidate all of its holdings in the companies listed above and enact a
//                     moratorium on all investments in arms manufacturers that supply weapons, munitions, and other
//                     military supplies to Israel.
//                 <br></br><br></br>
//                 <b>
//                 2. The termination of all corporate partnerships with companies complicit in the genocide,
//                 apartheid, or systematic cruelty towards children perpetrated against Palestinians in Gaza
//                 and the West Bank. 
//                 </b>
//                     &nbsp;Cornell currently maintains corporate partnerships with a number of weapons companies whose
//                     products have been used against civilians in Gaza. These companies include BAE Systems,
//                     Boeing, and Lockheed Martin. Cornell Systems Engineering also partners with RTX (Raytheon),
//                     which is described as being “an extended part of the Cornell Systems Engineering community.”
//                     Cornell’s partnerships with these weapons companies amounts to complicity in the genocide of
//                     the Palestinian people. We are therefore calling on Cornell University to sever their corporate
//                     partnerships with these companies as soon as possible. We call on Cornell University to begin
//                     this process immediately and to have fully dissolved these partnerships by the end of the 2024
//                     calendar year.
//                 <br></br><br></br>
//                 <b>
//                 3. A comprehensive ban on the research and development of any technologies used by the
//                 Israeli Offensive Forces at the Jacobs Cornell-Technion Institute in New York City. 
//                 </b>
//                     &nbsp;The Jacobs Technion-Cornell Institute, a partnership between Cornell University and the Israel
//                     Institute of Technology (Technion), is part of Cornell Tech, a campus for graduate research in
//                     New York City. Independently of Cornell Tech, Technion researches and develops geospatial,
//                     intelligence, and weapons technologies used by the Israeli Ministry of Defense. Cornell Tech’s
//                     publicly stated founding purpose is “to advance technology as a means to a better quality of life
//                     for all communities [...] around the world.” Its “Diversity and Inclusion” mission includes
//                     “[engaging] in research that promotes justice, equity, diversity, and inclusion” and “[educating
//                     and training] ethical technology leaders of the future.” In light of Technion’s numerous
//                     connections to Israel’s occupation and genocide in Palestine, Cornell Tech’s supposed
//                     commitment to ethical and just technological development rings hollow. We demand a
//                     comprehensive ban on the research and development of any technologies used by the Israel
//                     Offensive Forces at the Cornell Tech/Technion Campus in New York City.
//                 <br></br><br></br>
//                 As Israel continues its relentless genocide in Gaza and further militarizes its occupation of the West
//                 Bank, the world watches as Palestinians are displaced, starved, and killed every day. The horrors of
//                 Israel’s siege on Gaza are broadcast in full display across multiple news outlets and social media
//                 platforms, and yet, the American institutions that fuel this violence refuse to act.
//                 Thirty years ago, when over fifty other universities across the country divested from South African
//                 apartheid, Cornell faltered in its commitment to humanity and never severed its ties with a state
//                 dependent on the perpetuation of horrific racial violence. Today, the global community once again
//                 stands at a crossroad—Cornell University has the opportunity to do what it couldn’t three decades ago.
//                 Cornell University must make a choice: to toe the line drawn by a foreign nation and remain complicit
//                 in the genocide of the Palestinian people, or to establish itself as a leader among elite educational
//                 institutions by being the first to materially recognize the Palestinian right to life and dignity.
//                 We envision a future for Cornell University that does not fund and partner with the corporate entities
//                 responsible for the decimation of an entire people, their cultural artifacts, and the land they inhabit. The
//                 Board of Trustees must have the courage and moral fortitude to cut ties with Israel’s unrelenting
//                 campaign of violence against Palestine so that Cornell may truly do the greatest good.
//             </h3></p>
//         </div>
//     );
// };
 
// export default Demands;